import { Button, Modal, Text, Grid, InjectedModalProps, useToast, Input } from '@pancakeswap/uikit'
import Link from 'next/link'
import Image from 'next/image'
import useActiveWeb3React from 'hooks/useActiveWeb3React'

export const ModelNotify = ({ onDismiss }: { onDismiss: () => void }) => {
  const { account } = useActiveWeb3React()
  var newLocal = []
  const handleCancel = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }

  const handleOk = () => {
    if (!localStorage.getItem('isVotedCore')) {
      newLocal.push(account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    } else {
      const isVotes = JSON.parse(localStorage.getItem('isVotedCore'))
      newLocal.push(...isVotes, account)
      localStorage.setItem('isVotedCore', JSON.stringify(newLocal))
      onDismiss()
    }
  }
  return (
    <Modal title="Announcement!" onDismiss={handleCancel} style={{ padding: '10px', overflow: 'auto' }}>
      <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}>
        <Image src="/images/core/Logo.png" alt="" width="100px" height="40px" />
      </div>
      <Grid alignItems="center" justifyContent="center" mt="20px">
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '20px', textAlign: 'center' }}>Dear Core Stakers,</h1>
        <br />
        <h1 style={{ color: 'white', fontWeight: 600, fontSize: '16px', textAlign: 'center' }}>
          Currently, the MetaMask wallet system is undergoing updates and synchronization with the Blockchain Core
          network. As a result, the claim feature may encounter errors on devices running Windows and Wallet platforms.{' '}
          <br />
          <br />
          We would like to assure you: <br />
          <br />
          Users will be guaranteed the full duration of their mining packages as registered.
          <br />
          In the event that your personal wallet has not received any rewards due to the update process, all pending
          <br />
          rewards will be accumulated and credited in full on the next day once the system is fully operational.
          <br />
          Thank you for your understanding and support during this time!
          <br />
          Our development team is committed to ensuring the best experience for our users.
          <br />
          <br />
          Best regards,
          <br />
          The CoreStake Team.
          <br />
        </h1>
        <br />
      </Grid>
    </Modal>
  )
}
